<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdminOrUser()">
              <div class="card-header">
                <h3 class="card-title">Shop EDIT</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <form @submit.prevent="updateShop()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Store Name</label>
                            <input v-model="form.store_name" type="text" name="store_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('store_name') }">
                            <has-error :form="form" field="store_name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Store API Key</label>
                            <input v-model="form.store_api_key" type="text" name="store_api_key"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('store_api_key') }">
                            <has-error :form="form" field="store_api_key"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>Store APP Password</label>
                            <input v-model="form.store_app_password" type="text" name="store_app_password"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('store_app_password') }" autocomplete="false">
                            <has-error :form="form" field="store_app_password"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>Store Currency</label>
                            <select name="type" v-model="form.store_currency" id="store_currency" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                                <option value="">Select Currency</option>
                                <option value="INR">INR</option>
                                <option value="USD">USD</option>
                            </select>
                            <has-error :form="form" field="store_currency"></has-error>
                        </div>
                        
                        
                    </div>
                    <div class="modal-footer">
                        <router-link to="/shops" class="btn btn-secondary">Close
                        </router-link>
                        <button  type="submit" class="btn btn-success">Update</button>
                    </div>
                </form>
              </div>
              <!-- /.card-body -->
              
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdminOrUser()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                shops : {},
                imgUrl:'',
                file: null,
                form: new Form({
                    id : '',
                    store_name : '',
                    store_api_key: '',
                    store_app_password: '',
                    store_currency: '',
                    store_status: '',
                    file: null,
                })
            }
        },
        methods: {
            onChange(e) {
                this.file = e.target.files[0];
                console.log(this.file);
            }, 
            updateShop(){
                this.form.file=this.file;
                const dataEdit = new FormData();
                dataEdit.append('file', this.file);
                dataEdit.append('store_name', this.form.store_name);
                dataEdit.append('store_api_key', this.form.store_api_key);
                dataEdit.append('store_app_password', this.form.store_app_password);
                dataEdit.append('store_currency', this.form.store_currency);
                dataEdit.append('store_status', this.form.store_status);
                dataEdit.append('id', this.form.id);
                dataEdit.append('_method', 'PUT');
                /*console.log(this.form);
                console.log(data.get('file'));
                //console.log(data.store_name);
                console.log(data);*/
                console.log("Edit fileName");
                console.log(this.form.file);
                //this.form.put('http://127.0.0.1:8000/api/shop/'+this.form.id)
                axios.post("/api/shop/"+this.form.id, dataEdit)
                 .then((response) => {
                    // success
                    console.log("image Edit response");
                    console.log(response);
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    //return false;
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
                    this.$router.push({path: '/shops'})
                    
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            }
        },
        mounted() {
            console.log('Shop EDIT Component mounted.')
        },
        created() {
            console.log(this.$route.params.id);
            console.log(this.$route.params.id.image_store);
            this.imgUrl="http://127.0.0.1:8000/uploads/"+this.$route.params.id.image_store;
            console.log(this.imgUrl);
            this.form.reset();
            this.form.fill(this.$route.params.id);
            this.$Progress.start();
            
            this.$Progress.finish();
        }
    }
</script>
