<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
                <div class="card" v-if="$gate.isAdminOrUser()">
                  <div class="card-header">
                    <h3 class="card-title">Edit Order {{orders.order_no}}</h3>
                  </div>
                  <!-- /.card-header -->
                  <div class="col-md-12">
                      <div class="card-body table-responsive p-0">
                        <form @submit.prevent="updateOrder()" enctype="multipart/form-data">
                            <div class="form-group">
                                <div class="kt-form__label">
                                  <label>Payment Status:</label>
                                </div>
                                <select class="form-control selectpicker"  v-model="form.payment_status">
                                  <option value="paid">Paid</option>
                                          
                                </select>
                            </div>
                            <div class="form-group">
                              <div class="kt-form__label">
                                <label>Fullfilment Status:</label>
                              </div>
                                <select class="form-control selectpicker"  v-model="form.fullfilment_status" @change="changeOption('fullfilment')">
                                  <option value="Fulfilled">Fulfilled</option>
                                  <option value="Unfulfilled">Unfulfilled</option>
                                  <option value="Partially fulfilled">Partially fulfilled</option>
                                  <option value="Scheduled">Scheduled</option>
                                  <option value="On hold">On hold</option>
                                 
                                </select>
                            </div>
                            <div class="form-group">
                              <div class="kt-form__label">
                                <label>Order Totals:</label>
                              </div>
                                {{orders.order_total}}
                            </div>
                            <div class="form-group">
                              <div class="kt-form__label">
                                <label>Order Items:</label>
                              </div>
                                {{orders.item_name}} Quantity:{{orders.item_qty}} 
                            </div>

                            <table class="table table-hover" v-if="!lineitemcheck">
                              <thead>
                                <tr>
                                  
                                  <th>Item Name</th>
                                  <th>Qty</th>
                                  
                                </tr>
                              </thead>
                                <tbody>
                                <tr v-for="item in orders.lineitem" :key="item.id">
                                
                                <td class="text-capitalize">{{item.title}}
                                
                                <td>
                                    <input type="number" class="form-control" v-model="item.quantity" :id='item.lineitem_graphQL_id'>
                                </td>
                                </td>

                            </tr>
                          </tbody>
                        </table>
                            <div class="modal-footer">
                       
                                <router-link to="/orders" class="btn btn-secondary">Close
                                </router-link>
                                <button type="submit" class="btn btn-primary">Update</button>
                                
                            </div>
                        </form>
                
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div v-if="orders.customer && Object.keys(orders.customer).length" class="card-body table-responsive p-0">
                        <h5>Contact Information</h5>
                        <p>{{orders.customer_email}}</p>
                        <p>{{orders.customer.firstName}}</p>
                        <p>{{orders.customer.lastName}}</p>
                        <p>{{orders.customer.phonel}}</p>
                        <p>{{orders.customer.locale}}</p>
                        
                        <h5>Shipping address</h5>
                        <p>{{orders.shippingaddress.company}} </p>
                        <p>{{orders.shippingaddress.address1}} {{orders.shippingaddress.address2}}</p>
                        <p>{{orders.shippingaddress.zip}}</p>
                        <p>{{orders.shippingaddress.city}} {{orders.shippingaddress.provinceCode}}</p>
                        <p>{{orders.shippingaddress.country}}</p>

                        <h5>Billing address</h5>
                        <p>{{orders.billingaddress.company}} </p>
                        <p>{{orders.billingaddress.address1}} {{orders.billingaddress.address2}}</p>
                        <p>{{orders.billingaddress.zip}}</p>
                        <p>{{orders.billingaddress.city}} {{orders.billingaddress.provinceCode}}</p>
                        <p>{{orders.billingaddress.country}}</p>
                      </div>
                  </div>
                  <!-- /.card-body -->
                  
                </div>
            
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdminOrUser()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                checkmode: true,
                lineitemcheck:true,
                orders : {},
                imgUrl:'',
                file: null,
                form: new Form({
                    id : '',
                    payment_status : '',
                    fullfilment_status: '',
                    lineitem:[],
                    quantity:[],
                    reqlineitem:{
                        quantity:[],
                    },
                })
            }
        },
        methods: {
            loadOrder()
            {
                //axios.get("/api/order/"+this.form.id)
              axios.get("/api/order/"+this.$route.params.id).then(({ data }) => (
                        console.log(data),
                        this.orders = data.data,
                        this.form=this.orders,
                        console.log(this.form)
                    ));
                if(this.orders.customer != null)
                 {
                    console.log("customer exits");
                    this.checkmode= false
                 }

            },
            changeOption(type){
                switch (type) {
                  case 'fullfilment':
                    this.lineitemcheck= false
                  break; 
                }
              
            },
            updateOrder()
            {
                console.log(this.form);
                console.log(this.form.lineitem);
                
                //return false;
                  axios.put("/api/order/"+this.form.id, this.form)
                  .then((response)=>{
                      console.log(response);
                      Toast.fire({
                            icon: 'success',
                            title: response.data.message
                      });
                      //return false;
                      this.$Progress.finish();
                      this.$router.push({path: '/orders'})

                  })
                  .catch(()=>{

                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                  })
            },

        },
        mounted() {
            console.log('View Order Component mounted.')
        },
        created() {
            console.log(this.$route.params.id);
            this.$Progress.start();
            this.loadOrder();
            this.$Progress.finish();
        }
    }
</script>
