<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdminOrUser()">
              <div class="card-header">
                <h3 class="card-title">Vouchers List </h3>

                <div class="card-tools">
                <button  type="button" class="btn btn-sm btn-primary" @click="newModal" id="fetchOrder">
                      <i class="fa fa-plus-square"></i>
                       Fetch Vouchers
                </button>
                <div class="modal fade" id="addNewCustomer" tabindex="-1" role="dialog" aria-labelledby="addNewCustomer" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Fetch Vouchers</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> 
                        <form @submit.prevent="fetchVouchers()">
                            
                                
                            
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">FetchVocuhers</button>
                            </div>
                          </form>
                        </div>
                    </div>
                </div>
                <!-- <router-link to="/customers/add" class="btn btn-sm btn-primary" v-show="can('customer_create')" id="AddCustomer">Add </router-link> -->
                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th v-for="column in columns">
                      <a href="#" @click=sort(column.key)>{{ column.label  }}</a>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="voucher in orderedVouchers" :key="voucher.id">

                      <td>{{voucher.id}}</td>
                      <td class="text-capitalize">{{voucher.voucher_code}}</td>
                      
                      <td >
                          <span class="badge badge-success" v-if="voucher.sync_status == 'Success'">{{voucher.sync_status}}</span>
                      </td>
                      <td>
                        <router-link :to="{name: 'voucherview', params: { id: voucher.id}}" class="fa fa-eye blue viewVoucher" >
                        </router-link>
                        <button type="button" class="btn btn-sm btn-primary " @click="editModal(voucher)" >
                            <i class="fas fa-sync-alt"></i>
                         </button>
                        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title">Sync Shopify Store</h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                                </div> 
                                    <form @submit.prevent="SyncVoucher()">
                                    <div class="modal-body">
                                      <input type="hidden" v-model="form.id">
                                      <div class="form-group">

                                          <label>Select Shopify Store</label>
                                          <select class="form-control" v-model="form.shop_id">
                                            <option 
                                                v-for="(shop,index) in shops" :key="index"
                                                :value="index"
                                                :selected="index == form.shop_id">{{ shop }}</option>
                                          </select>
                                          
                                      </div>
                                      
                                    </div>
                                      <div class="modal-footer">
                                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                          <button type="submit" class="btn btn-primary">Sync</button>
                                      </div>
                                    </form>
                              </div>
                          </div>
                        </div>
                      </td>
                       
                        
                        <!-- <a href="#" @click="deleteCustomer(customer.id)" v-show="can('customer_delete')" class="deleteCustomer">
                            <i class="fa fa-trash red"></i>
                        </a> -->
                        
                      
                     
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="vouchers" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdminOrUser">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        
    </div>
  </section>
</template>

<script>

    export default {
        data () {
            return {
                editmode: false,
                currentSort:'id',
                currentSortDir:'asc',
                vouchers : {},
                sortvouchers:[],
                form: new Form({
                    id : '',
                    shop_id : '',
                }),
                fetchform: new Form({
                    id : '',
                    shop_id : '',
                }),
                shops: [],
                columns:[
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true
                    },
                    {
                        key: 'voucher_code',
                        label: 'Code',
                        sortable: true
                    },
                    {
                        key: 'sync_status',
                        label: 'Sync Status',
                        sortable: true
                    },
                    {
                        key: 'Action',
                        label: 'Action'
                        
                    },
                ],
                //columns: ['id', 'code', 'sku', 'qty', 'Action'],
            }
        },
        methods: {
            can(permissionName)
            {
                switch (permissionName) {
                  case 'customer_create':
                  axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                           
                            $('#AddCustomer').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('#AddCustomer').hide();
                            return false;
                        }
                    });
                  break;
                  case 'customer_edit':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.editCustomer').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.editCustomer').hide();
                            return false;
                        }
                        
                    });
                  break;
                  case 'customer_delete':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.deleteCustomer').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.deleteCustomer').hide();
                            return false;
                        }
                        
                    });
                  break;
                  case 'customer_sync':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.syncCustomer').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.syncCustomer').hide();
                            return false;
                        }
                        
                    });
                  break;
                  
                }

            },
            sort:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                  this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
                }
                this.currentSort = s;
              },
            getResults(page = 1) {
                  this.$Progress.start();
                  axios.get('api/voucher?page=' + page).then(({ data }) => (
                        this.vouchers = data.data,
                        this.sortvouchers=this.vouchers.data,
                        this.shops = data.message
                        ));

                  this.$Progress.finish();
            },
            editModal(voucher){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(voucher);
            },
            newModal(){
                this.editmode = false;
                this.fetchform.reset();
                $('#addNewCustomer').modal('show');
            },
            fetchVouchers(){
             this.$Progress.start();
              this.fetchmode=true
              $('#addNewCustomer').modal('hide');
              this.fetchform.post('api/voucher/fetchVouchers')
              .then(({ data }) => 
                (
                  console.log(data),
                  Toast.fire({
                        icon: 'success',
                        title: data.message
                  }),
                  this.fetchmode=false
                  //this.categories = data.data
               ));
              this.loadVouchers();
              this.$Progress.finish();
          },
          SyncVoucher()
          {
            this.$Progress.start();
            axios.post('api/voucher/syncVoucher',this.form)
            .then((data)=>{
                console.log(data);
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
              this.loadVouchers();
              this.$Progress.finish();
              
          },
            loadVouchers(){
                this.$Progress.start();

                if(this.$gate.isAdminOrUser()){
                  axios.get("api/voucher").then(({ data }) => 
                    (
                        this.vouchers = data.data,
                        this.sortvouchers=this.vouchers.data,
                        this.shops = data.message
                    )
                    );
                  console.log(this.sortvouchers);
                }

                this.$Progress.finish();
                },
            },
            mounted() {
                console.log('customer Component mounted.');
            },
            created() {
                this.$Progress.start();
                this.loadVouchers();
                this.$Progress.finish();
            },
            computed: {
                orderedVouchers: function () {
                  return this.sortvouchers.sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === 'desc') modifier = -1;
                    if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                  });
                }
            },
    }
</script>
