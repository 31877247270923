<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Cron List</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#addCron">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Event</th>
                      <th>Time</th>
                      <th>Attempts</th>
                      <th>Action </th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="cron in crons.data" :key="cron.id">

                      <td>{{cron.id}}</td>
                      <td>{{cron.name}}</td>
                      <td>{{cron.cron_time}} {{cron.cron_unit}}</td>
                      <td>{{cron.cron_attempts}}</td>
                      <td>
                         <a href="#" @click="editModal(cron)"  class="editPermission">
                        <i class="fa fa-edit blue"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addCron" tabindex="-1" role="dialog" aria-labelledby="addCron" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Create New Cron</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form  @submit.prevent="editmode ? updateCron() : createCron()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Event</label>
                            <select class="form-control selectpicker"  v-model="form.name" required>
                              <option value="Order Sync">Order Sync</option>
                              <!-- <option value="hour">hour</option>
                              <option value="day">day</option>
                              <option value="week">week</option>
                              <option value="month">month</option> -->
                            </select>
                            
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Cron Time</label>
                            <input v-model="form.cron_time" type="number" name="cron_time"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('cron_time') }">
                            <has-error :form="form" field="cron_time"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Cron Unit</label>
                            <select class="form-control selectpicker"  v-model="form.cron_unit">
                              <option value="minutes">minutes</option>
                              <option value="hour">hour</option>
                              <option value="day">day</option>
                              <option value="week">week</option>
                              <option value="month">month</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Cron Attempts</label>
                            <input v-model="form.cron_attempts" type="number" name="cron_attempts"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('cron_attempts') }">
                            <has-error :form="form" field="cron_attempts"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
              crons : {},
              // Create a new form instance
              form: new Form({
                  id : '',
                  name: '',
                  cron_time: '',
                  cron_unit: '',
                  cron_attempts: '',
              })
            }
        },
        methods: {

          loadCrons(){
            // if(this.$gate.isAdmin()){
              axios.get("api/cron").then(({ data }) => (this.crons = data.data));
            // }
          },
          editModal(cron){
                this.editmode = true;
                this.form.reset();
                $('#addCron').modal('show');
                this.form.fill(cron);
            },
          createCron(){
              this.$Progress.start();

              this.form.post('api/cron')
              .then((data)=>{
                  $('#addCron').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadCrons();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateCron(){
                this.$Progress.start();
                
                this.form.put('api/cron/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addCron').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
                    this.loadCrons();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
            },

        },
        mounted() {
            console.log('Cron Component mounted.')
        },
        created() {
            this.$Progress.start();

            this.loadCrons();

            this.$Progress.finish();
        }
    }
</script>
