export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/groups', component: require('./components/Groups.vue').default },
    { path: '/branches', component: require('./components/Branches.vue').default },
    { path: '/crons', component: require('./components/Crons.vue').default },
    { path: '/emails', component: require('./components/Emails.vue').default },
    { path: '/products/add', component: require('./components/product/AddProduct.vue').default },
    { name: 'productedit',  path: '/products/edit/:id', component: require('./components/product/EditProduct.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/customers/add', component: require('./components/customer/AddCustomer.vue').default },
    { name: 'customeredit',  path: '/customers/edit/:id', component: require('./components/customer/EditCustomer.vue').default },
    { path: '/customers', component: require('./components/customer/Customers.vue').default },
    { path: '/vouchers', component: require('./components/Vouchers.vue').default },
    { name: 'voucherview',  path: '/vouchers/view/:id', component: require('./components/ViewVoucher.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '/shops', component: require('./components/Shops.vue').default },
    { path: '/shops/add', component: require('./components/AddShops.vue').default },
    { name: 'shopedit',  path: '/shops/edit/:id', component: require('./components/EditShops.vue').default },
    { path: '/permissions', component: require('./components/Permissions.vue').default },
    { path: '/roles', component: require('./components/Roles.vue').default },
    { path: '/orders', component: require('./components/Orders.vue').default },
    { path: '/audits', component: require('./components/Audits.vue').default },
    { path: '/syncstores', component: require('./components/SyncStores.vue').default },
    { name: 'orderView',  path: '/:id', component: require('./components/ViewOrders.vue').default },
    { name: 'orderEdit',  path: '/orders/edit/:id', component: require('./components/EditOrder.vue').default },
    { path: '/order/process', component: require('./components/OrderPrcoess.vue').default },
    { path: '/order/fusionorderProcess', component: require('./components/FusionOrderPrcoess.vue').default },


    { path: '*', component: require('./components/NotFound.vue').default }
];
