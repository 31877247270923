<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdminOrUser()">
              <div class="card-header">
                <h3 class="card-title">Customer Edit</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <form @submit.prevent="updateCustomer()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>FirstName</label>
                                </div>
                                <input type="text" class="form-control" placeholder="FirstName" v-model="form.firstName">
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>LastName:</label>
                                </div>
                                <input type="text" class="form-control" placeholder="lastName"   v-model="form.lastName">
                              </div>
                            </div>
                        </div>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Email</label>
                                </div>
                                <input type="text" class="form-control" placeholder="Email" v-model="form.email">
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Phone number:</label>
                                </div>
                                <input type="text" class="form-control" placeholder="Phone"   v-model="form.phone">
                              </div>
                            </div>
                        </div>
                        <br/>
                        <div class="">
                          
                            <h3 class="">Address</h3>
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>FirstName</label>
                                </div>
                                <input type="text" class="form-control" placeholder="FirstName" v-model="form.firstNameAddress">
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>LastName:</label>
                                </div>
                                <input type="text" class="form-control" placeholder="lastName"   v-model="form.lastNameAddress">
                              </div>
                            </div>
                        </div>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Company</label>
                                </div>
                                <input type="text" class="form-control" placeholder="Company" id="Company" v-model="form.company"  >
                                
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Address</label>
                                </div>
                                <input type="text" class="form-control" placeholder="Address" id="Address" v-model="form.address"  >
                                
                               
                              </div>
                            </div>
                        </div>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Apartment, suite, etc.</label>
                                </div>
                                <input type="text" class="form-control" placeholder="province" id="province" v-model="form.province"  >
                                
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>City</label>
                                </div>
                                <input type="text" class="form-control" placeholder="City" id="City" v-model="form.city"  >
                                
                               
                              </div>
                            </div>
                        </div>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Country/region</label>
                                </div>
                                <input type="text" class="form-control" placeholder="Country" id="country" v-model="form.country"  >
                                
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Postal code</label>
                                </div>
                                <input type="text" class="form-control" placeholder="zip" id="zip" v-model="form.zip"  >
                                
                              </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Phone</label>
                                </div>
                                <input type="text" class="form-control" placeholder="Phone" id="phoneAddress" v-model="form.phoneAddress"  >
                              </div>
                            </div>
                        </div>
                        <br/>
                        <div class="">
                            <h3 class="">Notes</h3>
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Note</label>
                                </div>
                                <input type="text" class="form-control" placeholder="Note" v-model="form.note">
                              </div>
                            </div>
                        </div>
                        <br/>
                        <div class="">
                            <h3 class="">Tags</h3>
                            <div class="row">
                              <div class="col-sm">
                                <label>Tags</label>
                                  <vue-tags-input
                                    v-model="form.tag"
                                    :tags="form.tags"
                                    :autocomplete-items="filteredItems"
                                    @tags-changed="newTags => form.tags = newTags"
                                  />
                                  
                              </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                       
                        <router-link to="/customers" class="btn btn-secondary">Close
                        </router-link>
                        <button type="submit" class="btn btn-primary">Update</button>
                        
                    </div>
                </form>
              </div>
              <!-- /.card-body -->
              
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdminOrUser()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
    </div>
  </section>
</template>
<style>
  .options_values{
    display: inline;
  }
</style>
<script>
    import VueUploadMultipleImage from 'vue-upload-multiple-image';
    import VueTagsInput from '@johmun/vue-tags-input';
    import $ from 'jquery'
    export default {
        components: {
          VueTagsInput,
          VueUploadMultipleImage,
        },
        data () {
            return {
                editmode: false,
                shops : {},
                form: new Form({
                    id  : '',
                    firstName : '',
                    lastName: '',
                    email: '',
                    phone: '',
                    note: '',
                    tags: [],
                    address_id:'',
                    firstNameAddress:"",  
                    lastNameAddress:"",
                    address:"",
                    city:"",
                    province:"",
                    company:"",
                    country:"",
                    zip:"",
                    phoneAddress:"",
                }),
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {
            loadProduct(){
                //axios.get("/api/order/"+this.form.id)
                axios.get("/api/customer/"+this.$route.params.id).then(({ data }) => (
                        console.log(data),
                        this.form = data.data
                    ));      
            },
            onChange(e) {
                    this.file = e.target.files[0];
                    console.log(this.file);
            }, 
            updateCustomer(){
                  axios.put("/api/customer/"+this.form.id, this.form)
                  .then((response)=>{
                      console.log("image response");
                      console.log(response);
                      Toast.fire({
                            icon: 'success',
                            title: response.data.message
                      });
                      
                      this.$Progress.finish();
                      this.$router.push({path: '/customers'})

                  })
                  .catch(()=>{

                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                  })
            },
            loadTags(){
              axios.get("/api/tag/list").then(response => {
                  this.autocompleteItems = response.data.data.map(a => {
                      return { text: a.name, id: a.id };
                  });
              }).catch(() => console.warn('Oh. Something went wrong'));
          },
          
        },
        mounted() {
            console.log('Customer Edit Component mounted.');
            axios.get("/api/customer/"+this.$route.params.id).then(({ data }) => (
                        console.log(data),
                        this.form = data.data,
                        console.log(this.form)  
              )); 
        },
        created() {

            this.$Progress.start();
            this.loadTags();
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
        
    }
</script>




