<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdminOrUser()">
              <div class="card-header">
                <h3 class="card-title">Voucher View</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <form @submit.prevent="updateCustomer()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Code</label>
                                </div>
                                <p>{{ voucher.voucher_code}} </p>
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Product SKU</label>
                                </div>
                                <p>{{ voucher.product_sku}} </p>
                              </div>
                            </div>
                        </div>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Discount Shopify</label>
                                </div>
                                <p>{{ voucher.store_voucher_id}} </p>
                                
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>PriceRule Shopify</label>
                                </div>
                                 <p>{{ voucher.price_rule_shopify_id}} </p>
                              </div>
                            </div>
                        </div>
                        
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Usage count</label>
                                </div>
                                <p>{{ voucher.usage_count}} </p>
                                
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Quantity</label>
                                </div>
                                 <p>{{ voucher.quantity}} </p>
                              </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Target Selection</label>
                                </div>
                                <p>{{ voucher.target_selection}} </p>
                                
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Target Type</label>
                                </div>
                                 <p>{{ voucher.target_type}} </p>
                              </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Value</label>
                                </div>
                                <p>{{ voucher.value}} </p>
                                
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Value Type</label>
                                </div>
                                 <p>{{ voucher.value_type}} </p>
                              </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Voucher Type</label>
                                </div>
                                <p>{{ voucher.voucher_type}} </p>
                                
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>entitled_product_ids</label>
                                </div>
                                 <p>{{ voucher.entitled_product_ids}} </p>
                              </div>
                            </div>
                        </div>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Allocation Method</label>
                                </div>
                                <p>{{ voucher.allocation_method}} </p>
                                
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>discount</label>
                                </div>
                                 <p>{{ voucher.discount}} </p>
                              </div>
                            </div>
                        </div>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Starts at</label>
                                </div>
                                <p>{{ voucher.starts_at}} </p>
                                
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>End at</label>
                                </div>
                                 <p>{{ voucher.ends_at}} </p>
                              </div>
                            </div>
                        </div>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Customer Selection</label>
                                </div>
                                <p>{{ voucher.customer_selection}} </p>
                                
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>discount Product Level</label>
                                </div>
                                 <p>{{ voucher.discount_product_level}} </p>
                              </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <router-link to="/vouchers" class="btn btn-secondary">Close
                        </router-link>
                    </div>
                    
                </form>
              </div>
              <!-- /.card-body -->
              
            </div>
            <!-- /.card -->
          </div>
        </div>
        <div v-if="!$gate.isAdminOrUser()">
            <not-found></not-found>
        </div>
        <!-- Modal -->
    </div>
  </section>
</template>
<style>
  .options_values{
    display: inline;
  }
</style>
<script>
    import VueUploadMultipleImage from 'vue-upload-multiple-image';
    import VueTagsInput from '@johmun/vue-tags-input';
    import $ from 'jquery'
    export default {
        components: {
          VueTagsInput,
          VueUploadMultipleImage,
        },
        data () {
            return {
                editmode: false,
                shops : {},
                voucher : {},
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {  
        },
        mounted() {
            console.log('voucher view Component mounted.');
            axios.get("/api/voucher/"+this.$route.params.id).then(({  
              data }) => (
                this.voucher = data.data
              ));
            return false;
        },
        created() {
            this.$Progress.start();
            
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
        
    }
</script>




