<template>
  <section class="content">
    <div class="container-fluid"> 
        <div class="row">

          <div class="col-12"> 
            <div class="container">
                <div class="row">
                  <div class="col-sm">
                    <div class="kt-form__label">
                      <label>Model</label>
                    </div>
                    <input type="text" class="form-control" @change="changeFilterOption('order')" id="generalSearch" v-show="!loadingOrder" v-model="filter.order">
                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                      <span><i class="la la-search"></i></span>
                    </span>
                  </div>
                  <div class="col col-lg">
                    <div class="kt-form__label">
                      <label>DateRange:</label>
                    </div>
                    <template>
                      <date-range-picker
                        v-model="filter.dateRange"
                        :locale-data="{ firstDay: 0, format: 'yyyy-mm-dd' }"
                        @update="changeFilterOption('dateRange')"
                      ></date-range-picker>
                    </template>
                  </div>
                  <div class="col-sm">
                    <div class="kt-form__label">
                      <label>User</label>
                    </div>
                    <input type="text" class="form-control"  @change="changeFilterOption('customer')" id="customer" v-model="filter.customer" v-show="!loadingOrder">
                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                      <span><i class="la la-search"></i></span>
                    </span>
                  </div>
                  <div class="col-sm">
                    <div class="kt-form__label">
                      <label>Event</label>
                    </div>
                      <select class="form-control selectpicker" id="kt_form_status"  tabindex="-98" v-model="filter.status" @change="changeFilterOption('status')">
                        <option value="created">Created</option>
                        <option value="updated">Updated</option>
                        <option value="Deleted">Deleted</option>
                      </select> 
                  </div>
                  
                  <div class="col-sm">
                    <div class="kt-form__label">
                      <label></label>
                    </div>
                    <button type="button" class="btn btn-sm btn-primary" @click="clearSearch" style="margin-top: 15px;">
                      <i class="fa fa-times-circle"></i>
                      Clear Search
                  </button>
                  </div>
                </div>
            </div>
            <br/>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Audit log List</h3>

                <div class="card-tools">
                  
           
                </div> 
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th v-for="column in columns">
                      <a href="#" @click=sort(column.key)>{{ column.label  }}</a>
                      </th>
                    </tr>
                    
                  </thead>
                  <tbody>
                     <tr v-for="audit in orderedaudits" :key="audit.id">

                      <td>{{audit.id}}</td>
                      <td>{{audit.auditable_type}}</td>
                      <td>{{audit.event}}</td>
                      <td><p v-if="audit.user != null">{{audit.user.email}}</p></td>
                      <td>{{audit.created_at | formatDate }}</td>
                      <td>{{audit.old_values}}</td>
                      <td>{{audit.new_values}}</td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="audits" @pagination-change-page="getResults"></pagination>
              </div>
              
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- Modal -->
        

        
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import DateRangePicker from 'vue2-daterange-picker';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

    export default {
      components: {
          VueTagsInput,
          DateRangePicker,
        },
        data () {
            return {
                fetchmode: false,
                currentSort:'id',
                currentSortDir:'asc',
                audits : {},
                form: new Form({
                    id : '',
                    shop_id : '',
                }),
                filter:{
                  date:"",  //2020-05-26
                  order:"",
                  customer:"",
                  payment:"",
                  fullfillment:"",
                  status:"",
                  dateRange: {
                    startDate: null,
                    endDate: null,
                  },
                  start:"",
                  end:"",
                },
                shops: [],
                tag:  '',
                loadingOrder:false,
                autocompleteItems: [],
                sortaudits:[],
                columns:[
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true
                    },
                    {
                        key: 'auditable_type',
                        label: 'Model',
                        sortable: true
                    },
                    {
                        key: 'event',
                        label: 'Event',
                        sortable: true
                    },
                    {
                        key: 'user_id',
                        label: 'User',
                        sortable: true
                    },
                    {
                        key: 'created_at',
                        label: 'Time',
                        sortable: true
                    },
                    {
                        key: 'old_values',
                        label: 'Old Values',
                        sortable: true
                    },
                    {
                        key: 'new_values',
                        label: 'New Values',
                        sortable: true
                    },
                    
                ],
                //columns: ['id', 'Model', 'event', 'user', 'Time', 'Old Values', 'New Values'],
            }
        },
        methods: {
          sort:function(s) {
            //if s == current sort, reverse
            if(s === this.currentSort) {
              this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            this.currentSort = s;
          },
          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/audit?page=' + page).then(({ data }) => 
                (
                  this.audits = data.data,
                  this.sortaudits=this.audits.data,
                  console.log(this.audits)
                  
                ));

              this.$Progress.finish();
          },
          clearSearch(){
             
              this.filter.dateRange.startDate=null;
              this.filter.dateRange.endDate=null;
              this.filter.start=null;
              this.filter.fullfillment=null;
              this.filter.end=null;
              this.filter.order=null;
              this.filter.customer=null;
              this.filter.status=null;
              this.loadingSortOrder=false;
              this.loadAudits();
              Toast.fire({
                        icon: 'success',
                        title: 'Search cleared'
                  });
          },
          changeFilterOption(type){
                switch (type) {
                  case 'order':
                    
                  break;
                  case 'date':
                    
                  break;
                  case 'fullfillment':
                    
                  break;
                  case 'customer':
                    
                  break;
                  case 'status':
                    
                  break;
                  case 'dateRange':
                    //alert('date Range');
                    this.filter.start=this.convert(this.filter.dateRange.startDate);
                    this.filter.end=this.convert(this.filter.dateRange.endDate);
                  break;
                  
                } 
              this.loadingSortOrder=true;
              console.log(this.filter);
              this.fetchDataFromApi(false,false);
            },
            fetchDataFromApi(param=true,isNext=true){

            console.log("In Fetch Data filter function");
            console.log(this.filter);
              axios.post("/api/audit/filterList", this.filter)
              .then((response)=>{
                  console.log("response",this.response);
                  this.audits = response.data.data,
                  this.sortaudits=this.audits.data,
                  this.$Progress.finish();
                  Toast.fire({
                        icon: 'success',
                        title: 'Filter audit List'
                  });
                  
              })
              .catch(()=>{
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          loadAudits(){

            // if(this.$gate.isAdmin()){
              axios.get("api/audit").then(({ data }) => 
                (
                  
                  this.audits = data.data,
                  this.sortaudits=this.audits.data,
                  console.log(this.audits)
                ));
              
            // }
          },
          convert(str) {
            var date = new Date(str),
              mnth = ("0" + (date.getMonth() + 1)).slice(-2),
              day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
          }

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadAudits();

            this.$Progress.finish();
        },
        computed: {
            orderedaudits: function () {
              return this.sortaudits.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
              });
            }
        },
        
    }
</script>
