<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Sync Store History </h3>

                <div class="card-tools">
                  
                 <!--  <button v-show="!fetchmode" type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      FetechOrder
                  </button> -->
                </div> 
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th v-for="column in columns">
                      <a href="#" @click=sort(column.key)>{{ column.label  }}</a>
                      </th>
                    </tr>
                    
                  </thead>
                  <tbody>
                     <tr v-for="store in orderedstores" :key="store.id">

                      <td>{{store.id}}</td>
                      <td >{{store.sync_user}}</td>
                      <td class="text-capitalize">{{store.sync_name}}</td>
                      <td>Id:{{store.request_id }} Method: {{store.request_method }}</td>
                      <td>{{store.sync_response}}</td>
                      <td>{{store.sync_start }}</td>
                      <td>{{store.sync_end }}</td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="syncstores" @pagination-change-page="getResults"></pagination>
              </div>
              
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- Modal -->
        

        
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                fetchmode: false,
                currentSort:'id',
                currentSortDir:'asc',
                syncstores : {},
                form: new Form({
                    id : '',
                    shop_id : '',
                }),
                shops: [],
                tag:  '',
                autocompleteItems: [],
                sortsyncstores:[],
                columns:[
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true
                    },
                    {
                        key: 'sync_user',
                        label: 'User',
                        sortable: true
                    },
                    {
                        key: 'sync_name',
                        label: 'Name',
                        sortable: true
                    },
                    {
                        key: 'request_method',
                        label: 'Requests',
                        sortable: true
                    },
                    {
                        key: 'sync_response',
                        label: 'Response',
                        sortable: true
                    },
                    {
                        key: 'sync_start',
                        label: 'Start Time',
                        sortable: true
                    },
                    {
                        key: 'sync_end',
                        label: 'End Time'
                    },
                ],
                //columns: ['id', 'sync_user', 'sync_name', 'Requests', 'Response', 'Start time', 'End time'],
            }
        },
        methods: {
          sort:function(s) {
            //if s == current sort, reverse
            if(s === this.currentSort) {
              this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            this.currentSort = s;
          },
          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/syncstore?page=' + page).then(({ data }) => 
                (
                  this.syncstores = data.data,
                  this.sortsyncstores=this.syncstores.data
                ));

              this.$Progress.finish();
          },
          loadOrders(){

            // if(this.$gate.isAdmin()){
              axios.get("api/syncstore").then(({ data }) => 
                (
                  this.syncstores = data.data,
                  this.sortsyncstores=this.syncstores.data
                ));
              
            // }
          },
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadOrders();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
            orderedstores: function () {
              return this.sortsyncstores.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
              });
            }
        },
    }
</script>
