<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdminOrUser()">
              <div class="card-header">
                <h3 class="card-title">Product Edit</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <form @submit.prevent="updateProduct()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Title</label>
                            <input v-model="form.title" type="text" name="store_name"
                                class="form-control">
                            
                        </div>
                        <div class="form-group">
                            <label>Title Arabic</label>
                            <input v-model="form.title_ar" type="text" name="store_name"
                                class="form-control">
                            
                        </div>
                        <div class="form-group">
                            <label>Description </label>
                            <textarea v-model="form.body_html" type="textarea" name="store_api_key"
                                class="form-control"></textarea>
                            
                        </div>
                        <div class="form-group">
                            <label>Description Arabic</label>
                            <textarea v-model="form.body_html_ar" type="textarea" name="store_api_key"
                                class="form-control" ></textarea>
                            
                        </div>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Price</label>
                                </div>
                                <input type="number" class="form-control" placeholder="Price" v-model="form.price">
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Compare At Price:</label>
                                </div>
                                <input type="number" class="form-control" placeholder=" Compare At Price"   v-model="form.compare_price">
                              </div>
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Cost per item:</label>
                                </div>
                                  <input type="number" class="form-control" placeholder="Cost per item"   v-model="form.cost_item" >
                              </div>
                              
                            </div>
                        </div>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>SKU</label>
                                </div>
                                <input type="text" class="form-control" placeholder="SKU" v-model="form.sku">
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Barcode:</label>
                                </div>
                                <input type="text" class="form-control" placeholder="barcode"   v-model="form.barcode">
                                
                              </div>
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Quantity Available:</label>
                                </div>
                                  <input type="number" class="form-control" placeholder="Quantity" v-model="form.quantity" >
                              </div>
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Weight:</label>
                                </div>
                                <div class="d-flex">
                                  <input type="number" class="form-control" placeholder="Weight" v-model="form.weight" >
                                  <select class="form-control selectpicker"  v-model="form.weight_unit">
                                    <option value="POUNDS">lb</option>
                                    <option value="OUNCES">oz</option>
                                    <option value="KILOGRAMS">kg</option>
                                    <option value="GRAMS">g</option>
                                   
                                  </select>
                                </div>
                              </div>
                              
                            </div>
                        </div>
                        <br/>
                        <div class="">
                            <div class="row">
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Product status</label>
                                </div>
                                <select class="form-control selectpicker"  v-model="form.status">
                                    <option value="DRAFT">Draft</option>
                                    <option value="ACTIVE">Active</option>
                                  </select>
                              </div>
                              
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Product Vendor</label>
                                </div>
                                <input type="text" class="form-control" placeholder="Vendor" v-model="form.vendor" >
                               
                              </div>
                              <div class="col-sm">
                                <div class="kt-form__label">
                                  <label>Product type</label>
                                </div>
                                <input type="text" class="form-control" placeholder="type" id="customer" v-model="form.type" >
                              </div>
                              <div class="col-sm">
                                <label>Tags</label>
                                  <vue-tags-input
                                    v-model="form.tag"
                                    :tags="form.tags"
                                    :autocomplete-items="filteredItems"
                                    @tags-changed="newTags => form.tags = newTags"
                                  />
                                  
                              </div>
                              
                            </div>
                        </div>
                        <br/>
                         <div class="">
                                <div class="form-group">
                                    <div class="kt-form__label">
                                          <label>Media:</label>
                                    </div>
                                    <vue-upload-multiple-image
                                      @upload-success="uploadImageSuccess"
                                      @before-remove="beforeRemove"
                                      @edit-image="editImage"
                                      :data-images="images"
                                      :dragText="dragText"
                                      :browseText="browseText"
                                      :primaryText="media"
                                      :markIsPrimaryText="markIsPrimaryText"
                                      ></vue-upload-multiple-image>
                                </div>
                        </div>
                        <a href="#" id="AddOption" @click="showOption()" class="btn btn-primary btn-xs">
                            Add Options
                        </a>
                        <div id='ExtraOption'>
                          <div class="">
                              <div class="row">
                                <div class="col-sm">
                                  <div class="kt-form__label">
                                    <label>Option 1</label>
                                  </div>
                                  <input type="text" class="form-control" placeholder="option1" v-model="form.option1">
                                  <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                    <span><i class="la la-search"></i></span>
                                  </span>
                                </div>
                                
                                <div class="col-sm">
                                  <div class="kt-form__label">
                                    <label>Separate options with a comma</label>
                                  </div>
                                 <div class='tag-input'>
                                    <input type="text"
                                    id='option_value1' data-role="tagsinput" class='options_values'
                                     v-model="form.option_value1" />

                                    <input type="text"
                                      v-model="form.option_value1" class="form-control" readonly />

                                   <!--  <vue-tags-input
                                      v-model="form.option_value1"
                                      id="option_value1" 
                                      class="options_values"
                                    /> -->

                                  </div>
                                  
                                </div>
                              </div>
                          </div>
                          <div class="">
                              <div class="row">
                                <div class="col-sm">
                                  <div class="kt-form__label">
                                    <label>Option 2</label>
                                  </div>
                                  <input type="text" class="form-control" placeholder="option2" v-model="form.option2">
                                  
                                </div>
                                
                                <div class="col-sm">
                                  <div class="kt-form__label">
                                    <label>Separate options with a comma</label>
                                  </div>
                                  <div class='tag-input'>
                                    
                                    <input type="text" :value='form.option_value2' data-role="tagsinput" 
                                    id='option_value2' class='options_values' />    
                                    <input type="text"
                                      v-model="form.option_value2" class="form-control" readonly />    
                                  </div>
                                 
                                </div>
                              </div>
                          </div>
                          <div class="">
                              <div class="row">
                                <div class="col-sm">
                                  <div class="kt-form__label">
                                    <label>Option 3</label>
                                  </div>
                                  <input type="text" class="form-control" placeholder="option3" v-model="form.option3">
                                  <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                    <span><i class="la la-search"></i></span>
                                  </span>
                                </div>
                                
                                <div class="col-sm">
                                  <div class="kt-form__label">
                                    <label>Separate options with a comma</label>
                                  </div>
                                  
                                  <div class='tag-input'>
                                    <input type="text" :value='form.option_value3'  data-role="tagsinput" 
                                    id='option_value3' class='options_values' />
                                    <input type="text"
                                      v-model="form.option_value3" class="form-control" readonly />
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-lg-8" id="variants_options" >
                                <table id="table_variant">
                                    <tr>
                                        <th>Variant</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>SKU</th>
                                        <th>Barcode</th>
                                        
                                    </tr>
                                    <tbody id="table_body">
                                       <tr v-for="(item, index) in form.variant_name" >
                                        <td><input type='text' name='variant_name[]' class='form-control' :value="item"></td>
                                        <td><input type='number' name='price_variant[]' class='form-control' :value="form.price_variant[index]"></td> 
                                        <td><input type='number' name='quantity_variant[]' class='form-control' :value="form.quantity_variant[index]"></td>
                                        <td><input type='text' name='sku_variant[]' class='form-control' :value="form.sku_variant[index]"></td>
                                        <td><input type='text' name='barcode_variant[]' class='form-control' :value="form.barcode_variant[index]"></td>

                                       </tr> 
                                    </tbody>
                                </table>
                              </div>
                          </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                       
                        <router-link to="/products" class="btn btn-secondary">Close
                        </router-link>
                        <button type="submit" class="btn btn-primary">Update</button>
                        
                    </div>
                </form>
              </div>
              <!-- /.card-body -->
              
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdminOrUser()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
    </div>
  </section>
</template>
<style>
  .options_values{
    display: inline;
  }
</style>
<script>
    import VueUploadMultipleImage from 'vue-upload-multiple-image';
    import VueTagsInput from '@johmun/vue-tags-input';
    import $ from 'jquery'
    export default {
        components: {
          VueTagsInput,
          VueUploadMultipleImage,
        },
        data () {
            return {
                editmode: false,
                showOptiondiv:false,
                shops : {},
                images: [],
                imageData:{},
                imageFiles:[],
                dragText:'Add file',
                browseText:'or drop files to upload',
                media:'Media',
                markIsPrimaryText:'Media files',
                file: '',
                testOption:"",
                form: new Form({
                    id : '',
                    title : '',
                    title_ar: '',
                    body_html: '',
                    body_html_ar: '',
                    type: '',
                    tags: [],
                    vendor:"",  //2020-05-26
                    price:"",
                    compare_price:"",
                    cost_item:"",
                    sku:"",
                    status:"",
                    barcode:"",
                    quantity:"",
                    weight:"",
                    weight_unit:"",
                    imageFiles:[],
                    images: [],
                    option1:'',
                    option2:'',
                    option3:'',
                    option_value1:[],
                    option_value2:[],
                    option_value3:[],
                    variant_name:[],
                    price_variant:[],
                    quantity_variant:[],
                    sku_variant:[],
                    barcode_variant:[],
                }),
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {
            loadProduct(){
                //axios.get("/api/order/"+this.form.id)

                axios.get("/api/product/"+this.$route.params.id).then(({ data }) => (
                        console.log(data),
                        this.form = data.data,
                        this.images=data.data.filenames,
                        console.log(this.form),
                        console.log(this.form.option_value1),
                        console.log(this.testOption),
                        this.testOption=this.form.option_value1,
                        console.log(this.testOption)
                    )); 
                  if(this.form.option_value1!='')
                  {
                    console.log("Option Yes");
                     $('#ExtraOption').show();
                     $('#AddOption').hide();
                     //return true;

                  }else{
                    console.log("Option No")
                     $('#ExtraOption').hide();
                     $('#AddOption').show();
                     //return false;
                  }    
            },
            onChange(e) {
                    this.file = e.target.files[0];
                    console.log(this.file);
            }, 
            updateProduct(){

                  this.form.option_value1=$('#option_value1').val();
                  this.form.option_value2=$('#option_value2').val();
                  this.form.option_value3=$('#option_value3').val();
                  var values =  $("input[name='variant_name[]']")
                      .map(function(){return $(this).val();}).get();
                  this.form.variant_name=values;
                  var values =  $("input[name='price_variant[]']")
                      .map(function(){return $(this).val();}).get();
                  this.form.price_variant=values;
                  var values =  $("input[name='quantity_variant[]']")
                      .map(function(){return $(this).val();}).get();
                  this.form.quantity_variant=values;
                  var values =  $("input[name='sku_variant[]']")
                      .map(function(){return $(this).val();}).get();
                  this.form.sku_variant=values;
                  var values =  $("input[name='barcode_variant[]']")
                      .map(function(){return $(this).val();}).get();
                  this.form.barcode_variant=values;
                  console.log(this.form);
                  //console.log(this.form);
                  //console.log(this.imageFiles);
                  
                  //this.form.post('http://127.0.0.1:8000/api/shop')
                  axios.put("/api/product/"+this.form.id, this.form)
                  .then((response)=>{
                      console.log("image response");
                      console.log(response);
                      Toast.fire({
                            icon: 'success',
                            title: response.data.message
                      });
                      
                      this.$Progress.finish();
                      this.$router.push({path: '/products'})

                  })
                  .catch(()=>{

                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                  })
            },
            uploadImageSuccess(formData, index, fileList) {
              console.log('data', formData, index, fileList);
                //this.form.imageFiles.push(formData.getAll("file")[0]);
                this.form.images = fileList;
               
            },
            beforeRemove (index, done, fileList) {
              console.log('index', index, fileList)
              var r = confirm("remove image")
              if (r == true) {
                  console.log(fileList);
                  console.log(fileList[index].name);
                  var imgIndex = this.form.images.indexOf(fileList[index].name)
                  this.form.images.splice(imgIndex);
                done()
              } else {
              }
            },
            editImage (formData, index, fileList) {
              console.log('edit data', formData, index, fileList);
              this.imageData=formData;
            },
            loadTags(){
              axios.get("/api/tag/list").then(response => {
                  this.autocompleteItems = response.data.data.map(a => {
                      return { text: a.name, id: a.id };
                  });
              }).catch(() => console.warn('Oh. Something went wrong'));
          },
          showOption()
          {
            $('#ExtraOption').toggle();
            
            return false;
          },
          showOptions(){
            if(this.form.option_value1!='')
            {
              console.log("Option Yes");
               $('#ExtraOption').show();
               $('#AddOption').hide();
               return true;

            }else{
              console.log("Option No")
               $('#ExtraOption').hide();
               $('#AddOption').show();
               return false;
            }
          }
          
        },
        mounted() {
            console.log('Product edit Component mounted.');
            axios.get("/api/product/"+this.$route.params.id).then(( data ) => {
                        console.log(data);
                        console.log("OnlyData",data.data.data);
                        this.form = data.data.data;
                        this.images=data.data.data.filenames;
                        console.log("FORM",this.form);
                        console.log(this.form.option_value1);
                        console.log(this.testOption);
                        this.testOption=this.form.option_value1;
                        console.log(this.testOption);
                        console.log("Option1 ",this.form.option1);
                        if(this.form.option1==null)
                        {
                          
                           console.log("Option No")
                           $('#ExtraOption').hide();
                           $('#AddOption').show();

                        }else{
                          console.log("Option Yes");
                           $('#ExtraOption').show();
                           $('#AddOption').hide();  
                        }
              }); 
            
        },
        created() {

            this.$Progress.start();
            this.loadTags();
            
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
        
    }

        
            var checkArray = [];
            var option_tr='';
            var second_val='';
            var second_id ='';
            /*$("#option_value1").focus(function(event)
            {
              alert('OptionValues1');
            });*/

            $(".options_values").change(function(event)
            {
                
                alert("This is for all options");
                //$("#table_variant > tbody").html("");
                $('#table_body').empty();
                var option_1=$("#option_value1").val();
                console.log(option_1);
                let arr1 = option_1.toString().split(',');
                var option_2=$("#option_value2").val();
                console.log(option_2);
                let arr2 = option_2.toString().split(',');
                var option_3=$("#option_value3").val();
                console.log(option_3);
                let arr3 = option_3.toString().split(',');
                if(option_1=="" && option_2 =="" && option_3==""){
                    $("#variants_options").hide();
                }else{
                    $("#variants_options").show();
                }
                if(option_1!="" && option_2 =="" && option_3==""){
                    $.each(arr1, function(index1, value1) { 
                               //alert(index + ': ' + value);
                            
                                option_tr="<tr id="+ value1 +"><td><input type='text' v-model='form.variant_name' name='variant_name[]' class='form-control variant_name' value="+ value1 +"></td><td><input type='number' v-model='form.price_variant' name='price_variant[]' class='form-control price_variant'></td><td><input type='number' v-model='form.quantity_variant' name='quantity_variant[]' class='form-control quantity_variant'></td><td><input type='text' v-model='form.sku_variant' name='sku_variant[]' class='form-control sku_variant'></td><td><input type='text' v-model='form.barcode_variant' name='barcode_variant[]' class='form-control barcode_variant'></td></tr>";
                                 checkArray.push(value1);
                                $('#table_body').append(option_tr);
                            
                    }); 
                }
                
                if(option_1!="" && option_2 !="" && option_3==""){
                    $.each(arr1, function(index1, value1) { 
                               //alert(index + ': ' + value);
                               $.each(arr2, function(index2, value2) {
                                second_val=value1+"/"+value2;
                                second_id=value1+"_"+value2;
                                

                                 var id="#"+value1;
                                 $(id).remove();
                                 
                                 option_tr="<tr id="+ second_id +"><td><input type='text' v-model='form.variant_name' name='variant_name[]' class='form-control variant_name' value="+ second_val +"></td><td><input type='number' v-model='form.price_variant'  name='price_variant[]' class='form-control price_variant'></td><td><input type='number' v-model='form.quantity_variant' name='quantity_variant[]' class='form-control quantity_variant'></td><td><input type='text' v-model='form.sku_variant' name='sku_variant[]' class='form-control sku_variant'></td><td><input type='text' v-model='form.barcode_variant' name='barcode_variant[]' class='form-control barcode_variant'></td></tr>";
                                  checkArray.push(second_id);
                                 $('#table_body').append(option_tr);

                        });
                    });
                }

                if(option_1!="" && option_2 =="" && option_3!=""){
                    $.each(arr1, function(index1, value1) { 
                               //alert(index + ': ' + value);
                               $.each(arr3, function(index2, value2) {
                                second_val=value1+"/"+value2;
                                second_id=value1+"_"+value2;
                                

                                var id="#"+value1;
                                $(id).remove();
                                
                                option_tr="<tr id="+ second_id +"><td><input type='text' v-model='form.variant_name'  name='variant_name[]' class='form-control variant_name' value="+ second_val +"></td><td><input type='number' v-model='form.price_variant'  name='price_variant[]' class='form-control price_variant'></td><td><input type='number' v-model='form.quantity_variant' name='quantity_variant[]' class='form-control quantity_variant'></td><td><input type='text' v-model='form.sku_variant' name='sku_variant[]' class='form-control sku_variant'></td><td><input type='text' v-model='form.barcode_variant' name='barcode_variant[]' class='form-control barcode_variant'></td></tr>";
                                  checkArray.push(second_id);
                                 $('#table_body').append(option_tr);

                              

                            });
                    });
                }

                if(option_1!="" && option_2 !="" && option_3!=""){
                    
                    $.each(arr1, function(index1, value1) { 
                               //alert(index + ': ' + value);
                        $.each(arr2, function(index2, value2) {

                            $.each(arr3, function(index3, value3) {
                                second_val=value1+"/"+value2+"/"+value3;
                                var third_id=value1+"_"+value2+"_"+value3;
                                 var sec_row_id=value1+"_"+value2;

                                
                                     var id="#"+value1;
                                     var sec_id="#"+value1+"_"+value2;
                                     var third_del_id="#"+value1+"_"+value2+"_"+value3;
                                     //console.log(checkArray);
                                     //console.log("all three: "+third_id);
                                     $(sec_id).remove();
                                     $(third_del_id).remove();
                                option_tr="<tr id="+ third_id +"><td><input type='text' v-model='form.variant_name' name='variant_name[]' class='form-control variant_name' value="+ second_val +"></td><td><input type='number' v-model='form.price_variant' name='price_variant[]' class='form-control price_variant'></td><td><input type='number' v-model='form.quantity_variant' name='quantity_variant[]' class='form-control quantity_variant'></td><td><input type='text' v-model='form.sku_variant' name='sku_variant[]' class='form-control sku_variant'></td><td><input type='text' v-model='form.barcode_variant' name='barcode_variant[]' class='form-control barcode_variant'></td></tr>";
                                  checkArray.push(third_id);
                                   $('#table_body').append(option_tr);

                               

                            });
                        });
                    });
                }

                if(option_1=="" && option_2 !="" && option_3==""){
                    $.each(arr2, function(index1, value1) { 

                               //alert(index + ': ' + value);
                            

                                option_tr="<tr id="+ value1 +"><td><input type='text' v-model='form.variant_name' name='variant_name[]' class='form-control variant_name' value="+ value1 +"></td><td><input type='number' v-model='form.price_variant' name='price_variant[]' class='form-control price_variant'></td><td><input type='number' v-model='form.quantity_variant' name='quantity_variant[]' class='form-control quantity_variant'></td><td><input type='text' v-model='form.sku_variant' name='sku_variant[]' class='form-control sku_variant'></td><td><input type='text' v-model='form.barcode_variant' name='barcode_variant[]' class='form-control barcode_variant'></td></tr>";
                                 checkArray.push(value1);
                                $('#table_body').append(option_tr);
                            
                    });
                }

                if(option_1=="" && option_2 !="" && option_3!=""){
                    $.each(arr2, function(index1, value1) { 
                               //alert(index + ': ' + value);
                               $.each(arr3, function(index2, value2) {
                                second_val=value1+"/"+value2;
                                second_id=value1+"_"+value2;
                                

                                var id="#"+value1;
                                $(id).remove();
                                  checkArray.push(second_id);
                                 $('#table_body').append(option_tr);

                              
                            });
                    });
                }

                if(option_1=="" && option_2 =="" && option_3!=""){
                    $.each(arr3, function(index1, value1) { 
                               //alert(index + ': ' + value);
                                
                                //console.log("TR ID"+id);
                                //$(id).remove();
                           

                                option_tr="<tr id="+ value1 +"><td><input type='text' v-model='form.variant_name' name='variant_name[]' class='form-control variant_name' value="+ value1 +"></td><td><input type='number' v-model='form.price_variant'  name='price_variant[]' class='form-control price_variant'></td><td><input type='number' v-model='form.price_variant' name='quantity_variant[]' class='form-control quantity_variant'></td><td><input type='text' v-model='form.sku_variant' name='sku_variant[]' class='form-control sku_variant'></td><td><input type='text' v-model='form.barcode_variant' name='barcode_variant[]' class='form-control barcode_variant'></td></tr>";
                                 checkArray.push(value1);
                                $('#table_body').append(option_tr);
                        
                            
                    });
                }
                //console.log(arr1);
                //console.log(arr2);
                //console.log(arr3);
            });
            
            

            $("#removeVButton").click(function () {
                
                $(".element:last").remove();
            });
        

        function delete_variant(req){
            //alert(req.id);
            var split_id = req.id.split("-");
            var deleteindex = split_id[1];
            var del_id="#"+deleteindex;
            //alert(del_id);
            $(del_id).remove();

        }

</script>




