<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdminOrUser()">
              <div class="card-header">
                <h3 class="card-title">Shop Add</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <form @submit.prevent="createShop()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Store Name</label>
                            <input v-model="form.store_name" type="text" name="store_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('store_name') }">
                            <has-error :form="form" field="store_name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Store API Key</label>
                            <input v-model="form.store_api_key" type="text" name="store_api_key"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('store_api_key') }">
                            <has-error :form="form" field="store_api_key"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>Store APP Password</label>
                            <input v-model="form.store_app_password" type="text" name="store_app_password"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('store_app_password') }" autocomplete="false">
                            <has-error :form="form" field="store_app_password"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>Store Currency</label>
                            <select name="type" v-model="form.store_currency" id="store_currency" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                                <option value="">Select Currency</option>
                                <option value="INR">INR</option>
                                <option value="USD">USD</option>
                            </select>
                            <has-error :form="form" field="store_currency"></has-error>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <router-link to="/shops" class="btn btn-secondary">Close
                        </router-link>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                </form>
              </div>
              <!-- /.card-body -->
              
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdminOrUser()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                shops : {},
                file: '',
                form: new Form({
                    id : '',
                    store_name : '',
                    store_api_key: '',
                    store_app_password: '',
                    store_currency: '',
                    store_status: '',
                    file: '',
                })
            }
        },
        methods: {
        onChange(e) {
                this.file = e.target.files[0];
                console.log(this.file);
        }, 
          createShop(){
            const data = new FormData();
            data.append('file', this.file);
            data.append('store_name', this.form.store_name);
            data.append('store_api_key', this.form.store_api_key);
            data.append('store_app_password', this.form.store_app_password);
            data.append('store_currency', this.form.store_currency);
            data.append('store_status', this.form.store_status);

              //this.form.file=this.file;
              //this.form.append('file',this.file);
              console.log(this.form);
              console.log(data);
              //this.form.post('http://127.0.0.1:8000/api/shop')
              axios.post("/api/shop", data)
              .then((response)=>{
                  console.log("image response");
                  console.log(response);
                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });
                  
                  this.$Progress.finish();
                  this.$router.push({path: '/shops'})

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          }

        },
        mounted() {
            console.log('Shop Add Component mounted.')
        },
        created() {

            this.$Progress.start();
            
            this.$Progress.finish();
        }
        
    }
</script>
