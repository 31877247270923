<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
            
                <div class="card" v-if="$gate.isAdminOrUser()">
                  <div class="card-header">
                    <h3 class="card-title">View Order {{orders.order_no}}</h3>
                  </div>
                  <!-- /.card-header -->
                  <div class="col-md-12">
                      <div class="card-body table-responsive p-0">

                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th>Id</th>
                              <th>Item Name</th>
                              <th>Qty</th>
                              <th>Payment</th>
                              <th>Fulfillment</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                             <tr v-for="item in orders.lineitem" :key="item.id">
                              <td>{{item.id}}</td>
                              <td class="text-capitalize">{{item.title}}</td>
                              <td >{{item.quantity}}</td>
                              <td>{{orders.payment_status}}</td>
                              <td>{{orders.fullfilment_status}}</td>
                              <td>{{orders.order_total}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div v-if="orders.customer && Object.keys(orders.customer).length" class="card-body table-responsive p-0">
                        <h5>Contact Information</h5>
                        <p>{{orders.customer_email}}</p>
                        <p>{{orders.customer.firstName}}</p>
                        <p>{{orders.customer.lastName}}</p>
                        <p>{{orders.customer.phonel}}</p>
                        <p>{{orders.customer.locale}}</p>
                        
                        <h5>Shipping address</h5>
                        <p>{{orders.shippingaddress.company}} </p>
                        <p>{{orders.shippingaddress.address1}} {{orders.shippingaddress.address2}}</p>
                        <p>{{orders.shippingaddress.zip}}</p>
                        <p>{{orders.shippingaddress.city}} {{orders.shippingaddress.provinceCode}}</p>
                        <p>{{orders.shippingaddress.country}}</p>

                        <h5>Billing address</h5>
                        <p>{{orders.billingaddress.company}} </p>
                        <p>{{orders.billingaddress.address1}} {{orders.billingaddress.address2}}</p>
                        <p>{{orders.billingaddress.zip}}</p>
                        <p>{{orders.billingaddress.city}} {{orders.billingaddress.provinceCode}}</p>
                        <p>{{orders.billingaddress.country}}</p>
                      </div>
                  </div>
                  <!-- /.card-body -->
                  
                </div>
                <div class="modal-footer">
                        <router-link to="/orders" class="btn btn-secondary">Close
                        </router-link>
                </div>
                
            
            
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdminOrUser()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                checkmode: true,
                orders : {},
                imgUrl:'',
                file: null,
                form: new Form({
                    id : '',
                    store_name : '',
                    store_api_key: '',
                    store_app_password: '',
                    store_currency: '',
                    store_status: '',
                    file: null,
                })
            }
        },
        methods: {
            loadOrder(){
                //axios.get("/api/order/"+this.form.id)
                axios.get("api/order/"+this.$route.params.id).then(({ data }) => (
                        console.log(data),
                        this.orders = data.data
                        
                    ));
                if(this.orders.customer != null)
                 {
                    console.log("customer exits");
                    this.checkmode= false
                 }

            } 
        },
        mounted() {
            console.log('View Order Component mounted.')
        },
        created() {
            console.log(this.$route.params.id);
            this.$Progress.start();
            this.loadOrder();
             
            this.$Progress.finish();
        }
    }
</script>
