<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdminOrUser()">
              <div class="card-header">
                <h3 class="card-title">Customer List </h3>

                <div class="card-tools">
                <button  type="button" class="btn btn-sm btn-primary" @click="newModal" id="fetchOrder">
                      <i class="fa fa-plus-square"></i>
                       Fetch Customer
                </button>
                <div class="modal fade" id="addNewCustomer" tabindex="-1" role="dialog" aria-labelledby="addNewCustomer" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Fetch Shopify Customer</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> 
                        <form @submit.prevent="fetchCustomer()">
                            <div class="modal-body">
                                <div class="form-group">

                                    <label>Select Shopify Store</label>
                                    <select class="form-control" v-model="fetchform.shop_id">
                                      <option 
                                          v-for="(shop,index) in shops" :key="index"
                                          :value="index"
                                          :selected="index == form.shop_id">{{ shop }}</option>
                                    </select>
                                    <has-error :form="form" field="shop_id"></has-error>
                                </div>
                                
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">FetchCustomer</button>
                            </div>
                          </form>
                        </div>
                    </div>
                </div>
                <router-link to="/customers/add" class="btn btn-sm btn-primary" v-show="can('customer_create')" id="AddCustomer">Add </router-link>
                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th v-for="column in columns">
                      <a href="#" @click=sort(column.key)>{{ column.label  }}</a>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="customer in orderedCustomers" :key="customer.id">

                      <td>{{customer.id}}</td>
                      <td class="text-capitalize">{{customer.firstName}}</td>
                      <td >{{customer.email}}</td>
                      <td >{{customer.phone}}</td>
                     
                      
                      <td>
                        <router-link :to="{name: 'customeredit', params: { id: customer.id}}" class="fa fa-edit blue editCustomer" v-show="can('customer_edit')">
                        </router-link>

                       <!--  <a href="#" @click="editModal(shop)">
                            <i class="fa fa-edit blue">model</i>
                        </a> -->
                        
                        <a href="#" @click="deleteCustomer(customer.id)" v-show="can('customer_delete')" class="deleteCustomer">
                            <i class="fa fa-trash red"></i>
                        </a>
                        <button type="button" class="btn btn-sm btn-primary syncCustomer" @click="editModal(customer)" v-show="can('customer_sync')">
                            <i class="fas fa-sync-alt"></i>
                         </button>
                         <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                    <div class="modal-header">
                                  <h5 class="modal-title">Sync Shopify Store</h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                                    </div> 
                                    <form @submit.prevent="SyncCustomer()">
                                        <div class="modal-body">
                                            <input type="hidden" v-model="form.id">
                                            <div class="form-group">

                                          <label>Select Shopify Store</label>
                                          <select class="form-control" v-model="form.shop_id">
                                            <option 
                                                v-for="(shop,index) in shops" :key="index"
                                                :value="index"
                                                :selected="index == form.shop_id">{{ shop }}</option>
                                          </select>
                                          
                                                </div>
                                      
                                        </div>
                                      <div class="modal-footer">
                                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                          <button type="submit" class="btn btn-primary">Sync</button>
                                      </div>
                                </form>
                              </div>
                          </div>
                      </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="customers" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdminOrUser">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        
    </div>
  </section>
</template>

<script>

    export default {
        data () {
            return {
                editmode: false,
                currentSort:'id',
                currentSortDir:'asc',
                customers : {},
                sortcustomers:[],
                form: new Form({
                    id : '',
                    shop_id : '',
                }),
                fetchform: new Form({
                    id : '',
                    shop_id : '',
                }),
                shops: [],
                //columns: ['id', 'firstName', 'email', 'phone', 'Action'],
                columns:[
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true
                    },
                    {
                        key: 'firstName',
                        label: 'FirstName',
                        sortable: true
                    },
                    {
                        key: 'email',
                        label: 'Email',
                        sortable: true
                    },
                    {
                        key: 'phone',
                        label: 'Phone',
                        sortable: true
                    },
                    {
                        key: 'Action',
                        label: 'Action'
                        
                    },
                ],
            }
        },
        methods: {
            can(permissionName)
            {
                switch (permissionName) {
                  case 'customer_create':
                  axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                           
                            $('#AddCustomer').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('#AddCustomer').hide();
                            return false;
                        }
                    });
                  break;
                  case 'customer_edit':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.editCustomer').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.editCustomer').hide();
                            return false;
                        }
                        
                    });
                  break;
                  case 'customer_delete':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.deleteCustomer').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.deleteCustomer').hide();
                            return false;
                        }
                        
                    });
                  break;
                  case 'customer_sync':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.syncCustomer').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.syncCustomer').hide();
                            return false;
                        }
                        
                    });
                  break;
                  
                }

            },
            sort:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                  this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
                }
                this.currentSort = s;
              },
            getResults(page = 1) {

                  this.$Progress.start();
                  
                  axios.get('api/customer?page=' + page).then(({ data }) => (
                        this.customers = data.data,
                        this.sortcustomers=this.customers.data,
                        this.shops = data.message
                        ));

                  this.$Progress.finish();
            },
            editModal(customer){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(customer);
            },
            newModal(){
                this.editmode = false;
                this.fetchform.reset();
                $('#addNewCustomer').modal('show');
            },
            fetchCustomer(){
             this.$Progress.start();
              this.fetchmode=true
              $('#addNewCustomer').modal('hide');
              this.fetchform.post('api/customer/fetchShopifyCustomer')
              .then(({ data }) => 
                (
                  console.log(data),
                  Toast.fire({
                        icon: 'success',
                        title: data.message
                  }),
                  this.fetchmode=false
                  //this.categories = data.data
               ));
              this.loadCustomers();
              this.$Progress.finish();
          },
            SyncCustomer(){
              this.$Progress.start();
              
              axios.post('api/customer/syncCustomer',this.form)
              .then((data)=>{
                console.log(data);
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadCustomers();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
            },
            deleteCustomer(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        console.log(result.value);
                        // Send request to the server
                         if (result.value) {
                                axios.delete('api/customer/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadCustomers();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },
            loadCustomers(){
                this.$Progress.start();

                if(this.$gate.isAdminOrUser()){
                  axios.get("api/customer").then(({ data }) => 
                    (
                        this.customers = data.data,
                        this.sortcustomers=this.customers.data,
                        this.shops = data.message
                    )
                    );
                }

                this.$Progress.finish();
                },
            },
            mounted() {
                console.log('customer Component mounted.');

            },
            created() {

                this.$Progress.start();
                this.loadCustomers();
                this.$Progress.finish();
            },
            computed: {
                orderedCustomers: function () {
                  return this.sortcustomers.sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === 'desc') modifier = -1;
                    if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                  });
                }
            },
    }
</script>
