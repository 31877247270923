<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdminOrUser()">
              <div class="card-header">
                <h3 class="card-title">Update Email Setting</h3>

                
              </div>
              <!-- /.card-header -->
              <form @submit.prevent="editmode ? updatePermission() : createPermission()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Email To(Fusion Api Response)</label>
                            <input v-model="form.to_email" type="text" name="store_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('to_email') }" camelCase>
                            <has-error :form="form" field="to_email"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Email To(Payment Confirmation Provider)</label>
                            <input v-model="form.to_email_payment" type="text" name="store_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('to_email_payment') }" camelCase>
                            <has-error :form="form" field="to_email_payment"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Product Inventory Request Quantity</label>
                            <input v-model="form.req_qty" type="number" name="req_qty"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('req_qty') }" camelCase>
                            
                        </div>
                        <div class="form-group">
                            <label>Product Inventory Minimum Quantity Trigger</label>
                            <input v-model="form.min_qty" type="number" name="req_qty"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('min_qty') }" camelCase>
                            
                        </div>
                    </div>
                    <div class="modal-footer">
                        
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
              <!-- /.card-body -->
              
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdminOrUser()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">New Email Id </h5>
                    <h5 class="modal-title" v-show="editmode">Update Email's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="editmode ? updatePermission() : createPermission()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Email To(Fusion Api Response)</label>
                            <input v-model="form.to_email" type="text" name="store_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('to_email') }" camelCase>
                            <has-error :form="form" field="to_email"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Email To(Payment Confirmation Provider)</label>
                            <input v-model="form.to_email_payment" type="text" name="store_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('to_email_payment') }" camelCase>
                            <has-error :form="form" field="to_email_payment"></has-error>
                        </div>
                        <p>TEST</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                currentSort:'id',
                currentSortDir:'asc',
                emails : {},
                form: new Form({
                    id : '',
                    to_email : '',
                    to_email_payment : '',
                    req_qty : '',
                    min_qty : ''

                }),
                sortemails:[],
                //columns: ['id', 'title', 'Created', 'Action'],
                columns:[
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true
                    },
                    {
                        key: 'to_email',
                        label: 'Email',
                        sortable: true
                    },
                    {
                        key: 'created_at',
                        label: 'Created',
                        sortable: true
                    },
                    {
                        key: 'Action',
                        label: 'Action'
                        
                    },
                ],
                pageSize:10,
                currentPage:1,
            }
        },
        methods: {
            
            sort:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                  this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
                }
                this.currentSort = s;
              },
            getResults(page = 1) {
                  this.$Progress.start();
                  axios.get('api/email?page=' + page).then(({ data }) => 
                    (
                        this.emails = data.data,
                        this.sortemails=this.emails.data,
                        this.pageSize=this.emails.per_page,
                        this.currentPage=this.emails.current_page
                    ));
                  this.$Progress.finish();
            },
            updatePermission(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('api/email/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
                    //this.loadPermissions();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
            },
            editModal(permission){
                console.log("before change", permission);
                this.editmode = true;
                this.form.reset();
                //$('#addNew').modal('show');
                this.form.fill(permission);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deletePermission(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/email/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadPermissions();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                    })
            },
          loadPermissions(){
            this.$Progress.start();
            if(this.$gate.isAdminOrUser()){
              axios.get("api/email").then(({ data }) => 
                (
                    this.editModal(data.data)
                ));
            }
            this.$Progress.finish();
          },
          createPermission(){
              this.form.post('api/email')
              .then((response)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });
                  this.$Progress.finish();
                  this.loadPermissions();
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

        },
        mounted() {
            console.log('Email Setting Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadPermissions();
            this.$Progress.finish();
        },
        computed: {
            
        },
    }
</script>
