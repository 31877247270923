<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-shopping-basket"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Total Order</span>
                            <span class="info-box-number">
                            {{dashboard.orderCount}}
                            
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-store-alt"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Total Products</span>
                            <span class="info-box-number">{{dashboard.productCount}}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Sales</span>
                            <span class="info-box-number">{{dashboard.totalSales}}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Customers</span>
                            <span class="info-box-number">{{dashboard.customerCount}}</span>
                        </div>
                    <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
            <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-6">
                 <!-- Info Boxes Style 2 -->
                <div class="info-box mb-3 bg-secondary">
                <span class="info-box-icon"><i class="fas fa-clipboard-check"></i></span>

                <div class="info-box-content">
                    <span class="info-box-text">Paid Order</span>
                    <span class="info-box-number">{{dashboard.paid}}</span>
                </div>
                <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
                <div class="info-box mb-3 bg-success">
                <span class="info-box-icon"><i class="fas fa-crosshairs"></i></span>

                <div class="info-box-content">
                    <span class="info-box-text">Pending Order</span>
                    <span class="info-box-number">{{dashboard.pending}}</span>
                </div>
                <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
                <div class="info-box mb-3 bg-danger">
                <span class="info-box-icon"><i class="fas fa-strikethrough"></i></span>

                <div class="info-box-content">
                    <span class="info-box-text">Refunded Order</span>
                    <span class="info-box-number">{{dashboard.refunded}}</span>
                </div>
                <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
                <div class="info-box mb-3 bg-info">
                <span class="info-box-icon"><i class="fas fa-shipping-fast"></i></span>

                <div class="info-box-content">
                    <span class="info-box-text">Unpaid Order</span>
                    <span class="info-box-number">{{dashboard.unpaid}}</span>
                </div>
                <!-- /.info-box-content -->
                </div>   
                    
                </div>
            <!-- /.col -->

            <div class="col-md-6">
                <!-- Info Boxes Style 2 -->
                <div class="info-box mb-3 bg-secondary">
                <span class="info-box-icon"><i class="fas fa-tag"></i></span>

                <div class="info-box-content">
                    <span class="info-box-text">Fulfilled Order</span>
                    <span class="info-box-number">{{dashboard.fulfilled}}</span>
                </div>
                <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
                <div class="info-box mb-3 bg-success">
                <span class="info-box-icon"><i class="fas fa-luggage-cart"></i></span>

                <div class="info-box-content">
                    <span class="info-box-text">Scheduled Order</span>
                    <span class="info-box-number">{{dashboard.scheduled}}</span>
                </div>
                <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
                <div class="info-box mb-3 bg-danger">
                <span class="info-box-icon"><i class="fas fa-hand-holding-usd"></i></span>

                <div class="info-box-content">
                    <span class="info-box-text">On hold Order</span>
                    <span class="info-box-number">{{dashboard.onhold}}</span>
                </div>
                <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
                <div class="info-box mb-3 bg-info">
                <span class="info-box-icon"><i class="fas fa-ship"></i></span>

                <div class="info-box-content">
                    <span class="info-box-text">Unfulfilled Order</span>
                    <span class="info-box-number">{{dashboard.unfulfilled}}</span>
                </div>
                <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
                
            </div>
            <!-- /.col -->
            </div>
            <!-- /.row -->
        </div><!--/. container-fluid -->
    </section>
</template>

<script>
    export default {
        data () {
            return {
                dashboard: {
                    orderCount : '',
                    totalSales: '',
                    paid: '',
                    pending: '',
                    refunded:'',
                    unpaid:'',
                    fulfilled:'',
                    unfulfilled:'',
                    scheduled:'',
                    onhold:'',
                    productCount:'',
                    customerCount:'',
                    
                }
            }
        },
        methods:{

            loadSettings(){
                this.$Progress.start();
                if(this.$gate.isAdmin())
                { 
                  
                  axios.get("api/order/getDashboard").then(({ data }) => (
                    console.log("data",data),
                    this.dashboard = data.data,
                    console.log("dashboard",this.dashboard)
                    ));
                }
                this.$Progress.finish();
          },

        },
        mounted() {
            console.log('Component mounted.');
            this.$Progress.start();
            this.loadSettings();
            this.$Progress.finish();
        },
        created() {
            this.$Progress.start();
            //this.loadSettings();
            this.$Progress.finish();
        }
    }
   
</script>
